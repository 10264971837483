import * as S from "./styles";

import logoWhite from "../../../Assets/matu-logo-white.svg";
import { useNavigate } from "react-router-dom";
import NoAuthRoutes from "../../../Routes/Components/NoAuth";

function Header() {
  const navigate = useNavigate();

  const goToRoute = (name) => {
    return navigate(NoAuthRoutes[name].path);
  };

  return (
    <S.Container>
      <S.HeaderWrapper>
        <S.Logo src={logoWhite} alt="Matuu" onClick={() => goToRoute("Home")} />
        <S.NavWrapper>
          <S.NavContent>Serviços</S.NavContent>
          <S.NavContent>Tecnologias</S.NavContent>
          <S.NavContent>Quem Somos</S.NavContent>
          <S.NavContent>Portfólio</S.NavContent>
          <S.NavContent onClick={() => goToRoute("Contact")}>
            Contato
          </S.NavContent>
        </S.NavWrapper>
      </S.HeaderWrapper>
    </S.Container>
  );
}

export default Header;
