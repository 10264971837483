import React from "react";


import * as S from './styles'
import Header from "../../../Components/Global/Header";
import Footer from "../../../Components/Global/Footer";

function NoAuthLayout({ children }) {
  return (
    <S.Container>
      <Header />
      {children}
      <Footer />
    </S.Container>
  );
}

export default NoAuthLayout;
