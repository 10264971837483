import styled from "styled-components";

export const Container = styled.div`
  background-color: #191a1e;
  /* height: 600px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;

  @media (min-width: 768px) and (max-width: 1365px) {
    padding: 30px 0px;
    height: auto;
    gap: 50px;
  }
  @media (max-width: 767px) {
    padding: 10px 20px;
    height: auto;
  }
`;

export const Wrapper = styled.div`
  @media (min-width: 1366px) {
    width: 1366px;
    padding: 100px 50px;
    height: 100%;

    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    width: 100%;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    gap: 40px;
  }

  @media (max-width: 767px) {
    width: 100%;
    /* padding: 0px 50px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    gap: 40px;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;

  @media (min-width: 1366px) {
    gap: 200px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    justify-content: space-between;
    width: 100%;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const SubItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled.h2`
  color: var(--brand1);
  font-size: 24px;
`;

export const Logo = styled.img`
  @media (min-width: 1366px) {
    width: 180px;
    height: 72px;
    cursor: pointer;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    width: 150px;
    height: 60px;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    width: 150px;
    height: 60px;
  }
`;

export const Subtitle = styled.h2`
  font-size: 18px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
`;

export const Item = styled.li`
  font-size: 16px;

  :hover {
    text-decoration: underline;
    text-decoration-color: var(--brand1);
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
  }
`;

export const ContactWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    :hover {
    text-decoration: underline;
    text-decoration-color: var(--brand1);
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
  }

  a {
    color: white;
  }
`

export const LegalWrapper = styled.div`
  padding: 15px 0px;
  font-size: 14px;
`;
