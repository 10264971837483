import * as S from "./styles";

import logoWhite from "../../../Assets/matu-logo-white.svg";

import {
  BsWhatsapp,
  BsReverseLayoutTextSidebarReverse,
  BsInstagram,
  BsLinkedin,
} from "react-icons/bs";
import NoAuthRoutes from "../../../Routes/Components/NoAuth";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  const goToRoute = (name) => {
    window.scroll(0, 0)
    return navigate(NoAuthRoutes[name].path);
  };

  return (
    <S.Container>
      <S.Wrapper>
        <S.SubItemWrapper>
          <S.Logo src={logoWhite} alt="Matuu" />
          <S.Subtitle>Tecnologia que impulsiona resultados</S.Subtitle>
        </S.SubItemWrapper>
        <S.ItemsWrapper>
          <S.SubItemWrapper>
            <S.Title>O que fazemos</S.Title>
            <S.List>
              <S.Item>Landing Page</S.Item>
              <S.Item>Desenvolvimento web</S.Item>
              <S.Item>Sistema web</S.Item>
              <S.Item>Hotsite</S.Item>
              <S.Item>Aplicativo Android</S.Item>
              <S.Item>Aplicativo iOS</S.Item>
            </S.List>
          </S.SubItemWrapper>
          <S.SubItemWrapper>
            <S.Title>Navegue</S.Title>
            <S.List>
              <S.Item>Serviços</S.Item>
              <S.Item>Tecnologias</S.Item>
              <S.Item>Quem Somos</S.Item>
              <S.Item>Portfólio</S.Item>
              <S.Item onClick={() => goToRoute("Contact")}>Contato</S.Item>
            </S.List>
          </S.SubItemWrapper>
          <S.SubItemWrapper>
            <S.Title>Contato</S.Title>
            <S.ContactWrapper>
              <BsWhatsapp />
              <a
                href="https://api.whatsapp.com/send?phone=5511947633686"
                target="_blank"
                rel="noreferrer"
              >
                Whatsapp
              </a>
            </S.ContactWrapper>
            <S.ContactWrapper onClick={() => goToRoute("Contact")}>
              <BsReverseLayoutTextSidebarReverse />
              <p>Formulário</p>
            </S.ContactWrapper>
            <S.ContactWrapper>
              <BsInstagram />
              <a
                href="https://www.instagram.com/matuudeveloper"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </S.ContactWrapper>
            <S.ContactWrapper>
              <BsLinkedin />
              <a
                href="https://www.linkedin.com/company/matuu/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </S.ContactWrapper>
          </S.SubItemWrapper>
        </S.ItemsWrapper>
      </S.Wrapper>
      <S.LegalWrapper>Copyright © - 2023 - Matuu LTDA</S.LegalWrapper>
    </S.Container>
  );
}

export default Footer;
