import Contact from "../../Pages/Contact"
import Home from "../../Pages/Home"


const NoAuthRoutes = {
    Home: {
        name: 'Home',
        path: '/',
        element: Home,
    },
    Contact: {
        name: 'Contato',
        path: '/contato',
        element: Contact
    }

}

export default NoAuthRoutes