import GlobalStyle from "./Styles/global.js";
import Routes from "./Routes/index.jsx";

function App() {
  return (
    <>
      <GlobalStyle />
      <Routes />
    </>
  )
}

export default App;
