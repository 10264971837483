import * as S from "./styles";

import flutter from '../../../Assets/Technologies/flutter.svg'
import javascript from '../../../Assets/Technologies/javascript.svg'
import next from '../../../Assets/Technologies/next.svg'
import react from '../../../Assets/Technologies/react.svg'
import node from '../../../Assets/Technologies/logo-node.svg'
import uxui from '../../../Assets/Technologies/ux-ui.svg'
import androidIos from '../../../Assets/Technologies/android-ios.svg'
import figma from '../../../Assets/Technologies/figma.svg'
import web from '../../../Assets/Technologies/web.svg'
import mobile from '../../../Assets/Technologies/mobile.svg'
import responsive from '../../../Assets/Technologies/responsive.svg'

function Technologies() {
  return (
    <S.Container>
      <S.Wrapper>
        <S.TitleWrapper>
          <h2>Tecnologias</h2>
        </S.TitleWrapper>
        <S.SubtitleWrapper>
          <S.Subtitle>
            <span>Expertise</span> em Design e Tecnologia
          </S.Subtitle>
          <S.UnderSubtitle>
            Desenvolvemos soluções personalizadas que solucionam seus desafios e
            ampliam seu valor. Utilizamos desenvolvimento ágil, interação
            dinâmica e tecnologias avançadas para entregar resultados
            excepcionais.
          </S.UnderSubtitle>
        </S.SubtitleWrapper>
        <S.ContentWrapper>
          <S.CardWrapper>
            <h2>Design</h2>
            <S.IconsWrapper>
              <S.Card>
                <S.ImgCard>
                  <S.Img src={uxui} alt="Javascript" />
                </S.ImgCard>
                <S.ImgText>UX/UI Design</S.ImgText>
              </S.Card>
              <S.Card>
                <S.ImgCard>
                  <S.Img src={androidIos} alt="Javascript" />
                </S.ImgCard>
                <S.ImgText>Android / IOS</S.ImgText>
              </S.Card>
              <S.Card>
                <S.ImgCard>
                  <S.Img src={figma} alt="Javascript" />
                </S.ImgCard>
                <S.ImgText>Figma</S.ImgText>
              </S.Card>
              <S.Card>
                <S.ImgCard>
                  <S.Img src={web} alt="Javascript" />
                </S.ImgCard>
                <S.ImgText>Web</S.ImgText>
              </S.Card>
              <S.Card>
                <S.ImgCard>
                  <S.Img src={mobile} alt="Javascript" />
                </S.ImgCard>
                <S.ImgText>Mobile</S.ImgText>
              </S.Card>
              <S.Card>
                <S.ImgCard>
                  <S.Img src={responsive} alt="Javascript" />
                </S.ImgCard>
                <S.ImgText>Responsive</S.ImgText>
              </S.Card>
            </S.IconsWrapper>
          </S.CardWrapper>
          <S.CardWrapper>
            <h2>Desenvolvimento</h2>
            <S.IconsWrapper>
              <S.Card>
                <S.ImgCard>
                  <S.Img src={javascript} alt="Javascript" />
                </S.ImgCard>
                <S.ImgText>Javascript</S.ImgText>
              </S.Card>
              <S.Card>
                <S.ImgCard>
                  <S.Img src={react} alt="Javascript" />
                </S.ImgCard>
                <S.ImgText>ReactJS</S.ImgText>
              </S.Card>
              <S.Card>
                <S.ImgCard>
                  <S.Img src={next} alt="Javascript" />
                </S.ImgCard>
                <S.ImgText>NextJS</S.ImgText>
              </S.Card>
              <S.Card>
                <S.ImgCard>
                  <S.Img src={react} alt="Javascript" />
                </S.ImgCard>
                <S.ImgText>React Native</S.ImgText>
              </S.Card>
              <S.Card>
                <S.ImgCard>
                  <S.Img src={node} alt="Javascript" />
                </S.ImgCard>
                <S.ImgText>NodeJS</S.ImgText>
              </S.Card>
              <S.Card>
                <S.ImgCard>
                  <S.Img src={flutter} alt="Javascript" />
                </S.ImgCard>
                <S.ImgText>Flutter</S.ImgText>
              </S.Card>
            </S.IconsWrapper>
          </S.CardWrapper>
        </S.ContentWrapper>
      </S.Wrapper>
    </S.Container>
  );
}

export default Technologies;
