import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
        font-family: 'Urbanist', sans-serif;
    }
    :root{
        --brand1: #f6b21b;
        --brand2: #FA8C33;
        --brand3: #ACACAC;
        --grey1: #6F6F6F;
        --grey2: #727272;
        --grey3: #C4C3C2;
        --grey4: #646464;
        --grey5: #EAEAEA;
        --grey6: #fcfcfc;
        --whiteFixed: #FFFFFF;
        --alert1: #CD2B31;
        --alert2: #FDD8D8;
        --alert3: #FFE5E5;
        --alert4: #f12727;
        --sucess1: #18794E;
        --sucess2: #CCEBD7;
        --sucess3: #DDF3E4;
        --random1: #E34D8C;
        --random2: #C04277;
        --random3: #7D2A4D;
        --random4: #7000FF;
        --random5: #6200E3;
        --random6: #36007D;
        --random7: #349974;
        --random8: #2A7D5F;
        --random9: #153D2E;
        --random10: #6100FF;
        --random11: #5700E3;
        --random12: #30007D;
        --random13: #757575;
        --random14: #00004d;
        --black: #000
    }
    body{
        background-color: #fff;
        color: #000;
    }

    body, input, button {
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
    }

    button {
        cursor: pointer;
    }

    a {
        text-decoration: none;
    }

    ol, ul {
        list-style: none;
    }
`;


export const styleMedia = {
    mobile:' max-width: 767px'
}