import styled from "styled-components";

export const Container = styled.div`
  background-color: #fefefe;
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;

  @media (min-width: 768px) and (max-width: 1365px) {
    padding: 100px 0px;
    height: auto;
  }
  @media (max-width: 767px) {
    padding: 50px 20px;
    height: auto;
  }
`;

export const Wrapper = styled.div`
  @media (min-width: 1366px) {
    width: 1366px;
    padding: 0px 50px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    width: 100%;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }

  @media (max-width: 767px) {
    width: 100%;
    /* padding: 0px 50px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
`;

export const TitleWrapper = styled.div`
  background-color: #191a1e;
  padding: 5px 20px;
  color: white;
  align-self: flex-start;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;


export const Subtitle = styled.h2`
  @media (min-width: 1366px) {
    color: #000000;
    font-size: 40px;
    text-align: center;
    span {
      color: #f6b21b;
    }
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    color: #000000;
    font-size: 38px;
    text-align: center;
    span {
      color: #f6b21b;
    }
  }

  @media (max-width: 767px) {
    color: #000000;
    font-size: 35px;
    text-align: center;
    span {
      color: #f6b21b;
    }
  }
`;

export const UnderSubtitle = styled.p`
  @media (min-width: 1366px) {
    width: 900px;
    text-align: center;
    font-size: 20px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    width: 100%;
    text-align: center;
    font-size: 20px;
  }

  @media (max-width: 767px) {
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 50px;

  @media (min-width: 1366px) {
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    flex-direction: column;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 767px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const ImgCard = styled.div`
  width: 70px;
  height: 70px;
  background-color: #f6b21b;
  border-radius: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Img = styled.img`
  width: 40px;
  height: 40px;
`;

export const ImgText = styled.p`
  font-size: 14px;
  color: #5d626a;
`;
