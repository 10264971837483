import { BrowserRouter, Routes as DOMRoutes, Route } from "react-router-dom";

import React from "react";
import NoAuthRoutes from "./Components/NoAuth";
import NoAuthLayout from "../Styles/Layouts/NoAuthLayout";

function Routes() {
  return (
    <BrowserRouter>
      <DOMRoutes>
        {Object.entries(NoAuthRoutes).map(([key, value]) => (
          <Route
            key={key}
            path={value.path}
            element={
              <NoAuthLayout {...value}>
                {<value.element {...value} />}
              </NoAuthLayout>
            }
          />
        ))}
      </DOMRoutes>
    </BrowserRouter>
  );
}

export default Routes;
