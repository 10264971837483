import styled from "styled-components";

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.2rem;

  .label--input,
  .label--error {
    width: ${(props) => props.width};
    justify-content: flex-start;
    margin: 0 auto;
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  div > label > p {
    font-size: 0.875rem;
  }

  .label--error > label > p {
    padding-top: 0.15rem;
    font-size: 0.8rem;
    color: var(--alert1);
    font-style: italic;
  }
`;

export const DefaultInput = styled.input`
  padding: 0.6rem;
  background-color: var(--whiteFixed);
  color: var(--black);
  border: 1px solid var(--black);
  border-radius: 5px;

  /* &:hover {
    background-color: var(--grey8);
    border: 0.1rem solid var(--grey8);
  } */

  &:focus {
    border: 2px solid var(--brand1);
  }
`;
