import * as S from "./styles";
import WhyMatuu from "./WhyMatuu";
import MainContent from "./MainContent";
import OurServices from "./OurServices";
import Technologies from "./Technologies";
import Customers from "./Customers";

function Home() {
  return (
    <S.Container>
      <MainContent />
      <OurServices />
      <Technologies />
      <WhyMatuu />
      <Customers />
    </S.Container>
  );
}

export default Home;
