import styled from "styled-components";

export const Container = styled.div`
  background-color: #191a1e;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 200;

  @media (min-width: 1366px) {
    /* position: fixed; */
  }
`;

export const HeaderWrapper = styled.div`
  background-color: #191a1e;

  @media (min-width: 1366px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;
    width: 1366px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;
    width: 100%;
  }

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
    width: 100%;
  }
`;

export const Logo = styled.img`
  @media (min-width: 1366px) {
    width: 180px;
    height: 72px;
    cursor: pointer;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    width: 150px;
    height: 60px;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    width: 150px;
    height: 60px;
  }
`;

export const NavWrapper = styled.ul`
  display: flex;
  gap: 15px;

  @media (min-width: 1366px) {
  }

  @media (min-width: 768px) and (max-width: 1365px) {
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const NavContent = styled.li`
  color: #ffffff;
  cursor: pointer;

  :hover {
    /* color: #f6b21b; */
    text-decoration: underline;
    text-decoration-color: var(--brand1);
    text-underline-offset: 20px;
    text-decoration-thickness: 2px;
  }

  @media (min-width: 1366px) {
    font-size: 18px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    font-size: 16px;
  }

  @media (max-width: 767px) {
  }
`;
