import { useForm } from "react-hook-form";

import * as S from "./styles";
import { Form } from "@unform/web";
import { useState } from "react";
import { Input } from "../../../Components/Global/Input";

function FormContact() {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  //   const {
  //     register,
  //     handleSubmit,
  //     formState: { errors },
  //   } = useForm();

  // const handleSubmit = (data) => {
  //   console.log(data);
  // };

  return (
    <S.Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input label="Nome" name="name" register={register} />
        <Input label="E-mail" name="email" register={register} />
        <Input label="Telefone" name="phone" register={register} />
        <Input label="Empresa" name="company" register={register} />
        {/* <S.Input {...register("name")} />
        <S.Input {...register("email", { required: true })} />
        <S.Input {...register("phone", { pattern: /\d+/ })} />
        <S.Input {...register("company", { required: true })} /> */}
        <S.TextArea {...register("message")} />
        <S.Button>Enviar</S.Button>
      </form>
    </S.Container>
  );
}

export default FormContact;
