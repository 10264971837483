import styled from "styled-components";

export const Container = styled.div`
  background-color: #fefefe;

  height: 600px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @media (min-width: 1366px) {
    padding-top: 50px;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 40px 20px;
  }
`;

export const MainContent = styled.div`
  @media (min-width: 1366px) {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    width: 1366px;
    padding: 0px 50px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    width: 100%;
    padding: 0px 50px;
  }

  @media (max-width: 767px) {
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 30px;

  @media (max-width: 767px) {
    gap: 30px;
    align-items: center;
  }
`;

export const MasterContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;

  @media (max-width: 767px) {
    justify-content: center;
    align-items: center;
    align-items: center;
  }
`;

export const ContentTitle = styled.h2`
  @media (min-width: 1366px) {
    color: #000000;
    font-size: 40px;
    font-weight: bold;
    text-align: start;

    span {
      color: #f6b21b;
    }
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    color: #000000;
    font-size: 38px;
    font-weight: bold;
    text-align: start;

    span {
      color: #f6b21b;
    }
  }

  @media (max-width: 767px) {
    color: #000000;
    font-size: 35px;
    font-weight: bold;
    text-align: center;

    span {
      color: #f6b21b;
    }
  }
`;

export const ContentText = styled.p`
  @media (min-width: 1366px) {
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: start;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    color: #000000;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    text-align: start;
  }

  @media (max-width: 767px) {
    color: #000000;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    text-align: start;
    text-align: center;
  }
`;

export const Button = styled.button`
  color: #ffffff;
  font-weight: bold;
  border-radius: 20px;

  background-color: #f6b21b;
  border: 1px solid #f6b21b;

  @media (min-width: 1366px) {
    font-size: 20px;
    padding: 12px 30px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    font-size: 20px;
    padding: 12px 30px;
  }

  @media (max-width: 767px) {
    font-size: 20px;
    padding: 12px 30px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const SymbolImg = styled.img`
  @media (min-width: 1366px) {
    width: 350px;
    height: 350px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    width: 300px;
    height: 350px;
  }

  @media (max-width: 767px) {
  }
`;
