import * as S from "./styles";

import alapark from "../../../Assets/Customers/alapark.png";
import afonsofranca from "../../../Assets/Customers/afonsofranca.png";
import ventania from "../../../Assets/Customers/ventania-filmes.png";
import lilalilas from "../../../Assets/Customers/lilalilas.png";
import affit from "../../../Assets/Customers/affit.png";
import { goToUrl } from "../../../Helpers/Util";
import { Link } from "react-router-dom";

function Customers() {
  return (
    <S.Container>
      <S.Wrapper>
        <S.TitleWrapper>
          <h2>Clientes</h2>
        </S.TitleWrapper>
        <S.SubtitleWrapper>
          <S.Subtitle>
            Conheça algum dos nossos <span>cases de sucesso</span>
          </S.Subtitle>
          <S.UnderSubtitle>
            Desde a implementação de tecnologias avançadas até a criação de
            soluções exclusivas. Nós valorizamos nossos clientes e temos orgulho
            de ajudá-los a aumentar sua produtividade, otimizar seus processos e
            maximizar seus resultados.
          </S.UnderSubtitle>
        </S.SubtitleWrapper>
        <S.CardWrapper>
          <Link to={{ pathname: "https://www.alapark.com.br", search: true }} target="_blank">
            <S.Card>
              <S.CardImage src={alapark} alt="ALAPark" />
            </S.Card>
          </Link>
          {/* <S.Card onClick={() => goToUrl("https://www.alapark.com.br")}>
              <S.CardImage src={alapark} alt="ALAPark" />
            </S.Card> */}
          <a
            href="https://www.ventaniafilmes.com"
            target="_blank"
            rel="noreferrer"
          >
            <S.Card>
              <S.CardImage src={ventania} alt="ALAPark" />
            </S.Card>
          </a>
          <S.Card>
            <S.CardImage src={lilalilas} alt="ALAPark" />
          </S.Card>
          {/* <S.Card>
            <S.CardImage src={affit} alt="ALAPark" />
          </S.Card>
          <S.Card>
            <S.CardImage src={alapark} alt="ALAPark" />
          </S.Card>
          <S.Card>
            <S.CardImage src={alapark} alt="ALAPark" />
          </S.Card> */}
        </S.CardWrapper>
      </S.Wrapper>
    </S.Container>
  );
}

export default Customers;
