import styled from "styled-components";

export const Container = styled.div`
  background-color: #191a1e;
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) and (max-width: 1365px) {
    padding: 100px 0px;
    height: auto;
  }
  @media (max-width: 767px) {
    padding: 50px 20px;
    height: auto;
  }
`;

export const Wrapper = styled.div`
  @media (min-width: 1366px) {
    width: 1366px;
    padding: 0px 50px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    width: 100%;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }
  
  @media (max-width: 767px) {
    width: 100%;
    /* padding: 0px 50px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
`;

export const TitleWrapper = styled.div`
  background-color: #f6b21b;
  padding: 5px 20px;
  color: white;
  align-self: flex-start;
`;

export const Subtitle = styled.h2`
  @media (min-width: 1366px) {
    color: #ffffff;
    font-size: 40px;
    text-align: center;
    span {
      color: #f6b21b;
    }
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    color: #ffffff;
    font-size: 38px;
    text-align: center;
    span {
      color: #f6b21b;
    }
  }

  @media (max-width: 767px) {
    color: #ffffff;
    font-size: 35px;
    text-align: center;
    span {
      color: #f6b21b;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;



export const TextP = styled.p`
  @media (min-width: 1366px) {
    color: #ffffff;
    font-size: 20px;
    line-height: 34px;

    span {
      color: #f6b21b;
    }
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;

    span {
      color: #f6b21b;
    }
  }

  @media (max-width: 767px) {
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;
    text-align: center;

    span {
      color: #f6b21b;
    }
  }
`;
