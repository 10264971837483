import FormContact from "./Form";
import * as S from "./styles";

import { BsWhatsapp, BsInstagram, BsLinkedin } from "react-icons/bs";

function Contact() {
  return (
    <S.Container>
      <S.Wrapper>
        <S.TitleWrapper>
          <h2>Contato</h2>
        </S.TitleWrapper>
        <S.ContactWrapper>
          <S.DivContact>
            <S.SubItemWrapper>
              <S.Text>
                Entre em contato com a Matuu e descubra como podemos ajudar a
                levar o seu negócio para o próximo nível, , estamos prontos para
                oferecer o que há de melhor em soluções personalizadas para
                atender às suas necessidades.
              </S.Text>
              <S.ItemWrapper>
                <BsWhatsapp />
                <a
                  href="https://api.whatsapp.com/send?phone=5511947633686"
                  target="_blank"
                  rel="noreferrer"
                >
                  Whatsapp
                </a>
              </S.ItemWrapper>
              <S.ItemWrapper>
                <BsInstagram />
                <a
                  href="https://www.instagram.com/matuudeveloper"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </S.ItemWrapper>
              <S.ItemWrapper>
                <BsLinkedin />
                <a
                  href="https://www.linkedin.com/company/matuu/"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>
              </S.ItemWrapper>
            </S.SubItemWrapper>
          </S.DivContact>
          <S.DivForm>
            <S.Title>Preencha o formulário de contato:</S.Title>
            <FormContact />
          </S.DivForm>
        </S.ContactWrapper>
      </S.Wrapper>
    </S.Container>
  );
}

export default Contact;
