import * as S from "./styles";

import { BsCodeSlash, BsPencil, BsRocket } from "react-icons/bs";

function OurServices() {
  return (
    <S.Container>
      <S.Wrapper>
        <S.TitleWrapper>
          <h2>Nossos serviços</h2>
        </S.TitleWrapper>
        <S.Subtitle>
          Soluções <span>personalizadas</span>, <span>completas</span> e{" "}
          <span>seguras</span>.
        </S.Subtitle>
        <S.ContentWrapper>
            
          <S.CardService>
            <BsPencil color="#Fff" size="30px" />
            <S.CardTitle>Design UX/UI</S.CardTitle>
            <S.CardP>
              Criamos soluções personalizadas que atendem às necessidades dos
              usuários e geram resultados para seu negócio.
            </S.CardP>
          </S.CardService>

          <S.CardService>
            <BsCodeSlash color="#Fff" size="30px" />
            <S.CardTitle>Desenvolvimento Ágil</S.CardTitle>
            <S.CardP>
            Construímos, mantemos e escalamos suas aplicações usando as melhores práticas de desenvolvimento ágil.
            </S.CardP>
          </S.CardService>

          <S.CardService>
            <BsRocket color="#Fff" size="30px" />
            <S.CardTitle>Juntos após o lançamento</S.CardTitle>
            <S.CardP>
            Continuamos cuidando do código e das atualizações para garantir que ele esteja sempre funcionando perfeitamente para você e seus usuários.
            </S.CardP>
          </S.CardService>
        </S.ContentWrapper>
      </S.Wrapper>
    </S.Container>
  );
}

export default OurServices;
