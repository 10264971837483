import * as S from "./styles";

function WhyMatuu() {
  return (
    <S.Container>
      <S.Wrapper>
        <S.TitleWrapper>
          <h2>Por que Matuu?</h2>
        </S.TitleWrapper>
          <S.Subtitle>
            <span>"Match"</span> e <span>"You"</span> a combinação perfeita
          </S.Subtitle>
        <S.ContentWrapper>
          <S.TextP>
            Com a Matuu, você terá uma equipe dedicada de especialistas em
            tecnologia trabalhando para fornecer uma{" "}
            <span>solução completa</span> para sua presença online. Nossa
            abordagem personalizada garante que cada projeto seja projetado para
            atender às suas necessidades exclusivas e{" "}
            <span>atingir seus objetivos de negócios</span>.
          </S.TextP>
          <S.TextP>
            Não importa se você está começando do zero ou precisa de uma
            reformulação completa, a Matuu está aqui para ajudá-lo a se destacar
            online. Desde o design até o desenvolvimento e manutenção contínua,
            estamos comprometidos em garantir que seu site ou sistema seja
            <span> atraente, funcional e seguro</span>.
          </S.TextP>
        </S.ContentWrapper>
      </S.Wrapper>
    </S.Container>
  );
}

export default WhyMatuu;
