import styled from "styled-components";

export const Container = styled.div`
  background-color: #fefefe;
  height: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0px;

  @media (min-width: 768px) and (max-width: 1365px) {
    padding: 100px 0px;
    height: auto;
  }
  @media (max-width: 767px) {
    padding: 50px 20px;
    height: auto;
  }
`;

export const Wrapper = styled.div`
  @media (min-width: 1366px) {
    width: 1366px;
    padding: 0px 50px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    width: 100%;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }

  @media (max-width: 767px) {
    width: 100%;
    /* padding: 0px 50px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
`;

export const TitleWrapper = styled.div`
  background-color: #191a1e;
  padding: 5px 20px;
  color: white;
  align-self: flex-start;
`;

export const ContactWrapper = styled.div`
  @media (min-width: 1366px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;


export const DivContact = styled.div`
  display: flex;
  flex-direction: column;
`
export const DivForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const SubItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Text = styled.p`
  font-size: 18px;
`

export const Title = styled.h2`
  font-size: 24px;
  width: fit-content;
  padding: 5px 10px;
  color: white;
  background-color: var(--brand1);
`

export const ItemWrapper = styled.div`
display: flex;
align-items: center;
gap: 8px;
cursor: pointer;
width: fit-content;

a {
  color: #000;
  font-size: 18px;
}

p {
  font-size: 18px;
}


:hover {
text-decoration: underline;
text-decoration-color: var(--brand1);
text-underline-offset: 5px;
text-decoration-thickness: 2px;
}
`