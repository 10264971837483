import styled from "styled-components";

export const Container = styled.div`

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;

  :focus {
    border: 2px solid var(--brand1);
  }
`;

export const TextArea = styled.textarea`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
  height: 100px;
  resize: none;

  :focus {
    border: 2px solid var(--brand1);
  }
`;

export const Button = styled.button`
  color: #ffffff;
  font-weight: bold;
  border-radius: 20px;

  background-color: #f6b21b;
  border: 1px solid #f6b21b;

  @media (min-width: 1366px) {
    font-size: 20px;
    padding: 12px 30px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    font-size: 20px;
    padding: 12px 30px;
  }

  @media (max-width: 767px) {
    font-size: 20px;
    padding: 12px 30px;
  }
`;
