import styled from "styled-components";

export const Container = styled.div`
  background-color: #191a1e;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;

  @media (min-width: 768px) and (max-width: 1365px) {
    padding: 100px 0px;
    height: auto;
  }
  @media (max-width: 767px) {
    padding: 50px 20px;
    height: auto;
  }
`;

export const Wrapper = styled.div`
  @media (min-width: 1366px) {
    width: 1366px;
    padding: 0px 50px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    width: 100%;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }

  @media (max-width: 767px) {
    width: 100%;
    /* padding: 0px 50px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
`;

export const TitleWrapper = styled.div`
  background-color: #f6b21b;
  padding: 5px 20px;
  color: white;
  align-self: flex-start;
`;

export const Subtitle = styled.h2`
  @media (min-width: 1366px) {
    color: #ffffff;
    font-size: 38px;
    text-align: center;
    span {
      color: #f6b21b;
    }
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    color: #ffffff;
    font-size: 35px;
    text-align: center;
    span {
      color: #f6b21b;
    }
  }

  @media (max-width: 767px) {
    color: #ffffff;
    font-size: 30px;
    text-align: center;
    span {
      color: #f6b21b;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  
  gap: 30px;

  @media (min-width: 1366px) {
    display: flex;
    gap: 30px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    width: 100%;
    gap: 30px;
    overflow-x: auto;
    padding-bottom: 20px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 30px;
    overflow-x: auto;
    padding-bottom: 20px;
  }
`;

export const CardService = styled.div`
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 1366px) {
    min-width: 350px;
    min-height: 300px;
    width: 350px;
    height: 300px;
    padding: 48px 32px;
    border-radius: 30px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    min-width: 325px;
    min-height: 300px;
    width: 325px;
    height: 300px;
    padding: 48px 32px;
    border-radius: 30px;
  }

  @media (max-width: 767px) {
    min-width: 300px;
    min-height: 280px;
    width: 300px;
    height: 280px;
    padding: 48px 32px;
    border-radius: 30px;
    
  }
`;

export const CardTitle = styled.h2`

@media (min-width: 1366px) {
    font-size: 24px;
    font-weight: 600;
    color: #f6b21b;
}

@media (min-width: 768px) and (max-width: 1365px) {
      font-size: 22px;
      font-weight: 600;
      color: #f6b21b;
    }
    
    @media (max-width: 767px) {
      font-size: 20px;
      font-weight: 600;
      color: #f6b21b;
  }
`;

export const CardP = styled.p`

@media (min-width: 1366px) {
      color: #fff;
      font-size: 18px;
      font-weight: 300;
    }
    
    @media (min-width: 768px) and (max-width: 1365px) {
      color: #fff;
      font-size: 18px;
      font-weight: 300;
    }
    
    @media (max-width: 767px) {
      color: #fff;
      font-size: 16px;
      font-weight: 300;
  }
`;
