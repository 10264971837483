import * as S from "./styles";

import square from "../../../Assets/square-growth.svg";

function MainContent() {
  return (
    <S.Container>
      <S.MainContent>
        <S.ContentWrapper>
          <S.MasterContent>
            <S.ContentTitle>
              Tecnologia que <span>impulsiona</span> resultados
            </S.ContentTitle>
            <S.ContentText>
              Nossas soluções abrangem desde o desenvolvimento de softwares e
              aplicativos até a consultoria em tecnologia da informação,
              garantindo que sua empresa tenha tudo o que precisa para prosperar
              no mundo digital.
            </S.ContentText>
          </S.MasterContent>
          <S.Button>Entre em contato</S.Button>
        </S.ContentWrapper>
        <S.ImageWrapper>
          <S.SymbolImg src={square} alt="" />
        </S.ImageWrapper>
      </S.MainContent>
    </S.Container>
  );
}

export default MainContent;
